import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<footer id=\"footer\" class=\"footer\">\n  <div class=\"footer__container\">\n    <div class=\"footer-container__content\">\n      <div class=\"footer-container-content__copyrights\">\n        <span>{{t \"navigation.copyrights\"}} {{this.currentYear}} {{t \"navigation.pix\"}}</span>\n      </div>\n      <div class=\"footer-container-content-by\">\n        <span>BIMer.education est gratuit et ouvert à tous, il est proposé par:</span>\n        <img src=\"/images/logo-campus.jpg\" alt=\"Campus des métiers et des qualifications d'excellence\">\n      </div>\n    </div>\n\n  </div>\n\n</footer>", {"contents":"<footer id=\"footer\" class=\"footer\">\n  <div class=\"footer__container\">\n    <div class=\"footer-container__content\">\n      <div class=\"footer-container-content__copyrights\">\n        <span>{{t \"navigation.copyrights\"}} {{this.currentYear}} {{t \"navigation.pix\"}}</span>\n      </div>\n      <div class=\"footer-container-content-by\">\n        <span>BIMer.education est gratuit et ouvert à tous, il est proposé par:</span>\n        <img src=\"/images/logo-campus.jpg\" alt=\"Campus des métiers et des qualifications d'excellence\">\n      </div>\n    </div>\n\n  </div>\n\n</footer>","moduleName":"mon-pix/components/footer.hbs","parseOptions":{"srcName":"mon-pix/components/footer.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class Footer extends Component {
  @service url;
  @service intl;
  @service currentDomain;

  get shouldShowTheMarianneLogo() {
    return this.url.isFrenchDomainExtension;
  }

  get currentYear() {
    const date = new Date();
    return date.getFullYear().toString();
  }

  get cguUrl() {
    return this.url.cguUrl;
  }

  get dataProtectionPolicyUrl() {
    return this.url.dataProtectionPolicyUrl;
  }

  get accessibilityUrl() {
    return this.url.accessibilityUrl;
  }

  get supportHomeUrl() {
    return this.url.supportHomeUrl;
  }
}
